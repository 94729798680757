import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { inject, observer } from 'mobx-react';

import { RecordLink } from 'components/InventoryLinks';
import { Spinner } from 'components/Loader';
import { InstanceLink, InstanceNavItemOverrider } from 'components/Nav';
import { PageHeader } from 'components/Page';
import { TabView, TabViewsHeader, TabViewsSwitcher } from 'components/TabsPageView';
import Header from 'pages/Instances/RecordView/Header';
import HostContainersTab from 'pages/Instances/RecordView/HostContainersTab';
import HostOrContainerOverviewTab from 'pages/Instances/RecordView/HostOrContainerOverviewTab';
import HostsManagerConfigurationTab from 'pages/Instances/RecordView/HostsManagerConfigurationTab';
import HostsManagerSecretsTab from 'pages/Instances/RecordView/HostsManagerSecretsTab';
import HostsManagersHostsTab from 'pages/Instances/RecordView/HostsManagersHostsTab';
import K8sSpecTab from 'pages/Instances/RecordView/K8sSpecTab';
import TabWithDetails from 'pages/Instances/RecordView/overview';
import ScriptRunLogTab from 'pages/Instances/RecordView/ScriptRunLogTab';
import ScriptRunsTab from 'pages/Instances/RecordView/ScriptRunsTab';
import ScriptTab from 'pages/Instances/RecordView/ScriptTab';
import TerminalSessionsHistoryTab from 'pages/Instances/RecordView/TerminalSessionsHistoryTab';
import TerminalSessionTab from 'pages/Instances/RecordView/TerminalSessionTab';
import TopBarControls from 'pages/Instances/RecordView/TopBarControls/TopBarControls';
import UserTab from 'pages/Instances/RecordView/UserTab';
import NotFound from 'pages/NotFound';

const getPageHeaderProps = (props) => {
  let overrideHighlightedNavItem;

  const titles = [];
  let addRecordDisplayNameToTheTitle = true;

  if (props.record.app_1) {
    overrideHighlightedNavItem = 'hosts-managers';
    titles.push(
      <InstanceLink key="hosts-managers" to="/hosts-managers">
        Hosts Managers
      </InstanceLink>
    );
  } else if (props.record.script_1) {
    overrideHighlightedNavItem = 'scripts';
    titles.push(
      <InstanceLink key="scripts" to="/scripts">
        Scripts
      </InstanceLink>
    );
    props.record.script_1.path
      // cut 'first' slash
      .slice(1)
      .split('/')
      .slice(0, -1)
      .forEach((path, i, paths) => {
        titles.push(
          <InstanceLink key="scripts" to={`/scripts?dir=/${paths.slice(0, i + 1).join('/')}/`}>
            {path}
          </InstanceLink>
        );
      });
  } else if (props.record.script_run_1) {
    overrideHighlightedNavItem = 'scripts';
    titles.push(
      <InstanceLink key="scripts" to="/scripts">
        Scripts
      </InstanceLink>
    );
    const scriptPath = props.record.script_run_1.scriptObj.script_1.path;
    const scriptName = scriptPath.slice(scriptPath.lastIndexOf('/') + 1);
    const scriptDir = scriptPath.slice(0, scriptPath.lastIndexOf('/'));

    titles.push(
      <InstanceLink key="scriptPath" to={`/scripts?dir=${scriptDir}/`}>
        ...
      </InstanceLink>
    );
    titles.push(
      <InstanceLink key="scriptName" to={`/records/${props.record.script_run_1.scriptObj.root_1.id}`}>
        {scriptName}
      </InstanceLink>
    );
    titles.push(
      <InstanceLink key="runs" to={`/records/${props.record.script_run_1.script}/script-runs`}>
        Runs
      </InstanceLink>
    );
  } else if (props.record.container_1) {
    overrideHighlightedNavItem = 'containers';
    addRecordDisplayNameToTheTitle = false;
    titles.push(
      <InstanceLink key="hosts" to="/hosts">
        Hosts
      </InstanceLink>,
      <RecordLink recordId={props.record.container_1.host} className="" />,
      <InstanceLink key="host-containers" to={`/records/${props.record.container_1.host}/containers`}>
        Containers
      </InstanceLink>,
      props.record.container_1.name
    );
  } else if (props.record.host_1) {
    overrideHighlightedNavItem = 'hosts';
    titles.push(
      <InstanceLink key="hosts" to="/hosts">
        Hosts
      </InstanceLink>
    );
  } else if (props.record.data.has('std::system/User:1')) {
    overrideHighlightedNavItem = 'users';
    titles.push(
      <InstanceLink key="users" to="/users">
        Users
      </InstanceLink>
    );
  } else if (props.record.session_1) {
    if (props.record.session_1.targetObj && props.record.session_1.targetObj.loaded) {
      if (props.record.session_1.targetObj.host_1) {
        titles.push(
          <InstanceLink key="hosts" to="/hosts">
            Hosts
          </InstanceLink>,
          <RecordLink recordId={props.record.session_1.target} className="" />
        );
        overrideHighlightedNavItem = 'hosts';
      } else {
        const containerName = props.record.session_1.targetObj.container_1.name;
        const host = props.record.session_1.targetObj.container_1.host;
        titles.push(
          <InstanceLink key="hosts" to="/hosts">
            Hosts
          </InstanceLink>,
          <RecordLink recordId={host} className="" />,
          <InstanceLink key="host-containers" to={`/records/${host}/containers`}>
            Containers
          </InstanceLink>,
          <RecordLink recordId={props.record.session_1.target} className="" getText={() => containerName} />
        );
        overrideHighlightedNavItem = 'containers';
      }
      titles.push(
        <InstanceLink key="record-sessions" to={`/records/${props.record.session_1.target}/terminal-sessions`}>
          Sessions Audit
        </InstanceLink>
      );
    } else {
      titles.push('Sessions Audit');
    }
  }

  let documentTitle;
  if (props.record.inventory_1) {
    documentTitle = props.record.inventory_1.displayName;
  } else if (props.record.fs_node_1) {
    documentTitle = props.record.fs_node_1.name;
  } else {
    documentTitle = props.record.id.slice(0, 8);
  }
  if (addRecordDisplayNameToTheTitle) {
    titles.push(documentTitle);
  }

  const firstTitleItem = titles.length > 0 ? titles.shift() : 'Record';

  return {
    title: firstTitleItem,
    subTitle: titles,
    documentTitle: documentTitle,
    overrideHighlightedNavItem: overrideHighlightedNavItem,
  };
};

export default inject(
  'instance',
  'store'
)(
  observer((props) => {
    const { recordId } = useParams();

    useEffect(() => {
      return () => {
        props.instance.setNavItemOverride(null);
        const interval = setInterval(() => {
          props.instance.InventoryRecords.getById(recordId);
        }, 2000);
        return () => clearInterval(interval);
      };
    }, []);

    const record = props.instance.InventoryRecords.getById(recordId);
    if (record.notFound) {
      return <NotFound title={`Record with ID '${recordId}' is not found.`} />;
    }
    if (!record.loaded) {
      return <Spinner />;
    }
    if (record.script_run_1) {
      if (!(record.script_run_1.scriptObj && record.script_run_1.scriptObj.loaded)) {
        return <Spinner />;
      }
    }

    const { title, subTitle, documentTitle, overrideHighlightedNavItem } = getPageHeaderProps({ record: record });

    return (
      <TabViewsSwitcher>
        <PageHeader title={title} subTitle={subTitle} documentTitle={documentTitle}>
          <TopBarControls record={record} instance={props.instance} />
        </PageHeader>

        {overrideHighlightedNavItem && <InstanceNavItemOverrider baseUrl={overrideHighlightedNavItem} />}

        <TabViewsHeader>
          <Header record={record} instance={props.instance} />
        </TabViewsHeader>

        <TabView title="Overview" url="overview" key="overview" hidden={!HostOrContainerOverviewTab.suites(record)}>
          <HostOrContainerOverviewTab instance={props.instance} record={record} />
        </TabView>

        <TabView title="Containers" url="containers" key="host-containers" hidden={!HostContainersTab.suites(record)}>
          <HostContainersTab instance={props.instance} record={record} />
        </TabView>

        <TabView
          title="Sessions Audit"
          url="terminal-sessions"
          key="terminal-sessions"
          hidden={!TerminalSessionsHistoryTab.suites(record)}
        >
          <TerminalSessionsHistoryTab instance={props.instance} record={record} />
        </TabView>

        <TabView
          title="Recording"
          url="session-recordong"
          key="session-recording"
          hidden={!TerminalSessionTab.suites(record)}
        >
          <TerminalSessionTab instance={props.instance} record={record} />
        </TabView>

        <TabView
          title="Execution details"
          url="script-run-log"
          key="script-run-log"
          hidden={!ScriptRunLogTab.suites(record)}
        >
          <ScriptRunLogTab instance={props.instance} record={record} />
        </TabView>

        <TabView title="Script" url="script" key="script" hidden={!ScriptTab.suites(record)}>
          <ScriptTab instance={props.instance} record={record} />
        </TabView>

        <TabView title="Script runs" url="script-runs" key="script_runs" hidden={!ScriptRunsTab.suites(record)}>
          <ScriptRunsTab instance={props.instance} record={record} />
        </TabView>

        <TabView title="Activity" url="user-audit" key="user-audit" hidden={!UserTab.suites(record)}>
          <UserTab record={record} />
        </TabView>

        <TabView title="K8s spec" url="k8s-spec" key="k8s-spec" hidden={!K8sSpecTab.suites(record)}>
          <K8sSpecTab record={record} />
        </TabView>

        <TabView title="Managed hosts" url="app-hosts" key="app-hosts" hidden={!HostsManagersHostsTab.suites(record)}>
          <HostsManagersHostsTab record={record} />
        </TabView>

        <TabView
          title="Secrets"
          url="host-managers-secrets"
          key="app-secrets"
          hidden={!HostsManagerSecretsTab.suites(record, props.instance.amIAdmin)}
        >
          <HostsManagerSecretsTab record={record} />
        </TabView>

        <TabView
          title="Configuration"
          url="app-conf"
          key="app-conf"
          hidden={!HostsManagerConfigurationTab.suites(record)}
        >
          <HostsManagerConfigurationTab record={record} />
        </TabView>

        <TabView title="Detailed" url="detailed" key="detailed">
          <TabWithDetails instance={props.instance} record={record} />
        </TabView>
      </TabViewsSwitcher>
    );
  })
);
