import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { inject, observer } from 'mobx-react';
import { getParent, getType } from 'mobx-state-tree';

import moment from 'moment';
import DataTable, { createTheme } from 'react-data-table-component';
import 'regenerator-runtime/runtime';

import * as queryString from 'lib/query-string';

import { JustADot, StatusChart } from 'components/charts/StatusChart';
import Icon from 'components/Icon';
import { Spinner } from 'components/Loader';
import { InstanceLink } from 'components/Nav';
import Popover from 'components/Popover';
import {
  ColumnCustom,
  ColumnInventoryRecordStatus,
  ColumnLabels,
  ColumnModel,
  ColumnRecordLink,
  ColumnReferences,
  ColumnText,
  ColumnTimestamp,
} from 'components/table/TableS';
import TabWithDetails from 'pages/Instances/RecordView/overview';
import ScriptRunLogTab from 'pages/Instances/RecordView/ScriptRunLogTab';
import TerminalSessionTab from 'pages/Instances/RecordView/TerminalSessionTab';

createTheme(
  'itlook',
  {
    background: {
      default: 'transparent',
    },
  },
  'dark'
);

const ExpandedComponent = inject('instance')(
  observer((props) => {
    const [record, setRecord] = useState(null);

    useEffect(() => {
      setRecord(props.instance.InventoryRecords.getById(props.data.uuid));
    }, []);

    let comp = TabWithDetails;

    if (record) {
      if (record.session_1) {
        comp = TerminalSessionTab;
      }
      if (record.script_run_1) {
        comp = ScriptRunLogTab;
      }
    }

    return (
      <>
        {!record && <Spinner />}
        {record && React.createElement(comp, { record: record, instance: props.instance, nested: true })}
      </>
    );
  })
);

const ModelIconRender = inject('store')(
  observer((props) => {
    const display = props.short ? props.modelName.split('/').slice(-1) : props.modelName;
    if (!props.store.Models.loaded && !props.store.Models.loading) {
      props.store.Models.fetch();
    }
    let model = null;
    if (props.store.Models.loaded) {
      model = props.store.Models.getByIdentifier(props.modelName);
    }

    const sortMapModelName = (name) => {
      const prefix = {
        'std::types/Root:1': '0',
        'std::types/Versionable:1': '1',
        'std::types/Audit:1': '2',
        'std::types/Meta:1': '2',
        'std::types/Inventory:1': '2',
        'std::types/Journal:1': '2',
        'std::types/Auditable:1': '3',
      };
      return prefix[name] || '';
    };

    return (
      <Popover>
        <Popover.Trigger>
          <Link to={`/catalog/models/${props.modelName}`}>
            <div
              className="entity-type entity-icon"
              style={{ background: `url(${props.store.Models.getPicture(props.modelName)})` }}
            />
            {!props.iconOnly && display}
          </Link>
        </Popover.Trigger>
        <Popover.Window title="Model">
          <Link to={`/catalog/models/${props.modelName}`}>
            <div
              className="entity-type entity-icon-small"
              style={{ background: `url(${props.store.Models.getPicture(props.modelName)})` }}
            />
            {props.modelName}
          </Link>
          {model && (
            <div>
              <h5>Description</h5>
              <p>{model.definition.obj.description}</p>
            </div>
          )}
          {model && model.parts.length > 1 && <h5>Inherits </h5>}
          {model &&
            model.parts
              .filter((el) => el !== props.modelName)
              .sort((a, b) => {
                a = sortMapModelName(a);
                b = sortMapModelName(b);

                if (a > b) {
                  return 1;
                }
                if (a < b) {
                  return -1;
                }
                return 0;
              })
              .map((el) => (
                <div style={{ clear: 'both' }} key={el}>
                  <Link to={`/catalog/models/${el}`}>
                    <div
                      className="entity-type entity-icon-small"
                      style={{ background: `url(${props.store.Models.getPicture(el)})` }}
                    />
                    {el.slice(el.lastIndexOf('/') + 1)}
                  </Link>
                </div>
              ))}
        </Popover.Window>
      </Popover>
    );
  })
);

const ColumnCustomRender = observer((props) => {
  return props.column.render(props.row, props.table);
});

const ColumnTextRender = observer((props) => {
  let data = props.row.data.get(props.column.key);
  if (props.column.key.includes('.')) {
    const [model, field] = props.column.key.split('.');
    data = props.row.data.get(model).get(field);
  }

  return <p>{props.column.modifier(data)}</p>;
});

const ColumnTimestampRender = observer((props) => {
  const [model, field] = props.column.key.split('.');
  const ts = props.row.data.get(model).get(field);

  return (
    <Popover>
      <Popover.Trigger>
        <p>{ts ? moment(ts).format(props.column.format) : props.column.notSetMsg}</p>
      </Popover.Trigger>
      <Popover.Window title="Timestamp">
        <p>{ts || props.column.notSetMsg}</p>
      </Popover.Window>
    </Popover>
  );
});

const ColumnRecordLinkRender = observer((props) => {
  const data = props.row.data;
  const recordId = data.get('std::types/Root:1').get('id');
  const inventory = data.get('std::types/Inventory:1');
  const title = inventory ? inventory.get('displayName') : recordId.slice(0, 8);

  return (
    <InstanceLink to={`/${props.column.urlRoute}/${recordId}`} title={title} key={recordId}>
      {title}
    </InstanceLink>
  );
});

const ColumnReferencesRender = observer((props) => {
  const data = [];

  props.column.keys.forEach((key) => {
    const [model, field] = key.split('.');
    const refModel = props.row.data.get(model);
    if (refModel) {
      const refData = refModel.get(field);
      if (refData) {
        data.push(...(Array.isArray(refData) ? refData : [refData]));
      }
    }
  });

  const resolved = props.table.data.resolved;
  return (
    <>
      {Array.from(data || []).map((refId) => {
        if (!resolved.get(refId)) {
          return null;
        }
        const refData = resolved.get(refId).data;
        const inventory = refData.get('std::types/Inventory:1');
        const title = inventory ? inventory.get('displayName') : refId.slice(0, 8);
        return (
          <div style={{ whiteSpace: 'nowrap', display: 'block' }} key={`${refId}`}>
            {/* <ModelIconRender modelName={refData.get('@model')} short iconOnly /> */}
            <InstanceLink to={`/records/${refId}`} title={title} style={{ display: 'inline-block' }}>
              {title}
            </InstanceLink>
          </div>
        );
      })}
    </>
  );
});

const ColumnModelRender = observer((props) => {
  return (
    <ModelIconRender
      modelName={props.row.data.get('@model')}
      short={props.column.short}
      iconOnly={props.column.iconOnly}
    />
  );
});

const ColumnLabelsRender = observer((props) => {
  const [model, field] = props.column.key.split('.');
  let data = props.row.data.get(model).get(field);
  data = Array.isArray(data) ? data : [data];

  return (
    <>
      {Array.from(data)
        .filter((e) => e)
        .map((label) => {
          const queryParams = queryString.stringify({
            q: props.column.isolatedSearch ? `${props.column.key}=='${label}'` : `search('${label}')`,
            page: 1,
          });

          return (
            <InstanceLink
              key={`/records/${label}`}
              to={`/search?${queryParams}`}
              title={label}
              className="btn btn-text btn-default btn-small btn-label"
            >
              {label}
            </InstanceLink>
          );
        })}
    </>
  );
});

const ColumnInventoryRecordStatusRender = observer((props) => {
  const statusable = props.row.data.get('std::types/Statusable:1');
  const status = statusable.get('status');
  const recordId = props.row.data.get('std::types/Root:1').get('id');
  const data = props.table.data.extra.get('statusStats').toJSON()[recordId];

  const resolved = props.table.data.resolved;
  const appId = props.row.data.get('std::types/Root:1').get('app');
  const app = resolved.get(appId);
  const appType = app.data.get('std::system/App:1').get('applicationType');

  // in future probably should make this configurable
  const appDisplayName =
    {
      'std::Hosts:1': 'Host Manager',
      'std::System:1': 'System',
    }[appType] || appType;

  if (app) {
    const appStatusable = app.data.get('std::types/Statusable:1');
    const appStatus = appStatusable.get('status');
    if (appStatus !== 'ok') {
      const lastUpdate = moment(props.row.data.get('std::types/Versionable:1').get('updatedAt')).format(
        'MMM D YYYY, HH:mm'
      );

      return (
        <Popover>
          <Popover.Trigger>
            <Icon className="icon status-danger" />
          </Popover.Trigger>
          <Popover.Window
            title={`${appDisplayName} is in ${appStatus} state: ${appStatusable.get('statusDescription')}`}
          >
            <p>
              <JustADot status={status} title={status} />
              {statusable.get('statusDescription')}
            </p>
            <p>Last status reported at {lastUpdate} </p>
            <p>It is impossible to determine the actual status of the resource at the moment</p>
          </Popover.Window>
        </Popover>
      );
    }
  }

  return (
    <div>
      {!props.row.active && <JustADot status={status} title={status} />}
      {props.row.active && (
        <StatusChart status={status} statusDescription={statusable.get('statusDescription')} data={data.stats} />
      )}
    </div>
  );
});

const loadColumns = (columns) => {
  return columns.map((column) => {
    const component = {
      [ColumnCustom.name]: ColumnCustomRender,
      [ColumnText.name]: ColumnTextRender,
      [ColumnRecordLink.name]: ColumnRecordLinkRender,
      [ColumnReferences.name]: ColumnReferencesRender,
      [ColumnLabels.name]: ColumnLabelsRender,
      [ColumnTimestamp.name]: ColumnTimestampRender,
      [ColumnInventoryRecordStatus.name]: ColumnInventoryRecordStatusRender,
      [ColumnModel.name]: ColumnModelRender,
    }[getType(column).name];

    return {
      name: column.name,
      selector: (row) => React.createElement(component, { column: column, row: row, table: getParent(column, 2) }),
      sortField: column.sortKey,
      sortable: column.sortKey !== null,
      ...column.opts.toJSON(),
    };
  });
};

const NoDataComponent = observer((props) => {
  if (props.error) {
    return (
      <div className="errorMessage">
        <p className="errorHeader">
          {props.error.status} ({props.error.code})
        </p>
        {props.error.details.map((el) => (
          <p key="el"> {el} </p>
        ))}
      </div>
    );
  }

  return <p>No records to display</p>;
});

export const Table = observer((props) => {
  const store = props.store;

  useEffect(() => {
    return store.stopAutoRefresh;
  }, []);

  return (
    <DataTable
      className="rdt_TopContainer"
      title={store.data.title}
      columns={loadColumns(store.columns)}
      data={store.rows}
      expandableRows
      expandableRowsComponent={ExpandedComponent}
      pagination
      paginationServer
      paginationServerOptions={{
        persistSelectedOnSort: true,
        persistSelectedOnPageChange: true,
      }}
      paginationPerPage={store.paginationDefaultRowsPerPage}
      paginationRowsPerPageOptions={store.paginationRowsPerPageOptions}
      onChangeRowsPerPage={store.changeRowsPerPage}
      paginationTotalRows={store.data.pagination.total}
      onChangePage={store.changePage}
      onSort={store.changeSort}
      sortServer
      selectableRows={store.selectable}
      noDataComponent={<NoDataComponent error={store.error} />}
      persistTableHead
      fixedHeader
      disabled={store.loading && store.inited}
      progressPending={!store.inited}
      progressComponent={<Spinner />}
      dense
      highlightOnHover
      onSelectedRowsChange={(selected) => store.changeSelected(selected.selectedRows)}
      selectableRowSelected={(row) => store.isSelectedRow(row)}
      onRowMouseEnter={(row) => {
        row.setActive(true);
      }}
      onRowMouseLeave={(row) => {
        row.setActive(false);
      }}
      theme="itlook"
    />
  );
});
