import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { sortObjects } from 'lib/sort-utils';
import Icon from 'components/Icon';
import { UserLink } from 'components/InventoryLinks';
import { Spinner } from 'components/Loader';
import { ConfirmationAboutDeleteWindow } from 'components/ModalWindow';
import { InstanceLink } from 'components/Nav';
import { ContainerBox, PageHeader } from 'components/Page';
import Table, { TableCell, TableRow } from 'components/Table';
import ScriptsStore from 'pages/Instances/Scripts/ScriptsListS';
var defaultIcon = 'file';
var icons = {
    folder: 'folder',
    bash: defaultIcon,
    parent: 'folder',
};
var Row = inject('instance')(observer(function (props) {
    var navigate = useNavigate();
    var iconClass;
    if (props.iconClass) {
        iconClass = props.iconClass;
    }
    else {
        if (props.type) {
            iconClass = icons[props.type];
        }
        iconClass = iconClass || defaultIcon;
    }
    var name;
    if (props.link && props.name) {
        // this link is redundant cause the whole raw is clickable, but showing
        //  cursor on the name makes people happy
        name = React.createElement(InstanceLink, { to: props.link }, props.name);
    }
    else {
        name = props.name;
    }
    var onClick = function () {
        if (props.link) {
            navigate("/i/".concat(props.instance.name).concat(props.link));
        }
    };
    var handleOnDeleteClick = function (e) {
        e.stopPropagation();
        if (props.onDeleteClick) {
            props.onDeleteClick();
        }
    };
    return (React.createElement(TableRow, { onClick: onClick, title: props.title },
        React.createElement(TableCell, null,
            React.createElement(Icon, { className: iconClass })),
        React.createElement(TableCell, { key: "name" }, name),
        React.createElement(TableCell, { key: "type" }, props.scriptType),
        React.createElement(TableCell, { key: "owner" }, props.author && React.createElement(UserLink, { recordId: props.author })),
        React.createElement(TableCell, { key: "updatedAt" }, props.updatedAt && props.updatedAt.format('MMM D YYYY, HH:mm')),
        props.showDeleteColumn && (React.createElement(TableCell, { key: "delete" },
            React.createElement(Icon, { onClick: handleOnDeleteClick, className: "delete" }))),
        props.showEmptyDeleteColumn && React.createElement(TableCell, null)));
}));
var ScriptRow = observer(function (props) { return (React.createElement(Row, { key: props.script.recordID, name: props.script.name, type: props.script.language, author: props.script.author, updatedAt: props.script.updatedAt, showDeleteColumn: props.showDeleteColumn && props.script.canBeDelete(), showEmptyDeleteColumn: props.showDeleteColumn && !props.script.canBeDelete(), onDeleteClick: function () { return props.pageStore.setScriptToDelete(props.script); }, scriptType: props.script.scriptType, link: "/records/".concat(props.script.recordID) })); });
var ScriptsTable = observer(function (_a) {
    var pageStore = _a.pageStore;
    var tree = pageStore.tree.get(pageStore.currentDirPath);
    if (tree === undefined) {
        return React.createElement(Spinner, null);
    }
    if (tree.dirs.length === 0 && tree.scripts.length === 0) {
        if (pageStore.loading) {
            // there are no cached scripts yet, but the new loading is not finished
            return React.createElement(Spinner, null);
        }
        return 'There are no scripts.';
    }
    var dirs = tree.dirs.slice().sort(function (dirA, dirB) {
        var res = dirA.localeCompare(dirB);
        if (pageStore.sortByField === 'name' && pageStore.reverseOrder) {
            res = -res;
        }
        return res;
    });
    var scripts = tree.scripts
        .slice()
        .sort(function (scriptA, scriptB) {
        return sortObjects(scriptA, scriptB, pageStore.sortByField, ['name', 'updatedAt', 'language'], pageStore.reverseOrder);
    });
    // FIXME(andreykurilin): check here whether any of script is owned by
    //    current user to decide whether we can add delete button
    var getDirLink = function (dirName) { return "/scripts?dir=".concat(dirName); };
    return (React.createElement(Table, { className: "table-files-list", headers: pageStore.tableHeaders, onSortChange: pageStore.sortBy, selected: pageStore.sortByField },
        React.createElement(Row, { name: "", iconClass: "levelup", title: pageStore.isRootDir() ? 'You are on the root level' : 'Navigate to parent directory', link: !pageStore.isRootDir() ? getDirLink(pageStore.parentDir) : undefined, showDeleteColumn: false, showEmptyDeleteColumn: pageStore.userIsAdmin }),
        dirs.map(function (dirName) { return (React.createElement(Row, { key: dirName, iconClass: "folder", name: dirName, link: getDirLink(pageStore.absPath(dirName)), showDeleteColumn: false, showEmptyDeleteColumn: pageStore.userIsAdmin })); }),
        scripts.map(function (script) { return (React.createElement(ScriptRow, { key: script.recordID, script: script, showDeleteColumn: pageStore.userIsAdmin, pageStore: pageStore })); })));
});
export default inject('instance')(observer(function (props) {
    var pageStore = useState(ScriptsStore.create({}))[0];
    var searchParams = useSearchParams()[0];
    useEffect(function () {
        pageStore.linkInstanceStore(props.instance);
        if (!props.instance.Applications.loaded && !props.instance.Applications.loading) {
            props.instance.Applications.fetch();
        }
    }, []);
    useEffect(function () {
        pageStore.setCurrentDir(searchParams.get('dir') || '/');
    }, [searchParams]);
    var body = React.createElement(Spinner, null);
    var title = 'Scripts';
    var subTitles = [];
    if (props.instance.Applications.loaded) {
        body = (React.createElement(React.Fragment, null,
            React.createElement(ScriptsTable, { pageStore: pageStore }),
            pageStore.scriptToDelete && (React.createElement(ConfirmationAboutDeleteWindow, { onClose: function () { return pageStore.setScriptToDelete(null); }, onCancel: function () { return pageStore.setScriptToDelete(null); }, onDelete: pageStore.deleteScript }))));
        if (pageStore.currentDirPath !== '/') {
            title = React.createElement(InstanceLink, { to: "/scripts" }, "Scripts");
            var curPosition_1 = '/';
            subTitles = pageStore.currentDirPath
                // first item is empty
                .slice(1)
                .split('/')
                .filter(function (i) { return i; })
                .map(function (dirName) {
                curPosition_1 = "".concat(curPosition_1).concat(dirName, "/");
                if (curPosition_1 === pageStore.currentDirPath) {
                    return dirName;
                }
                return React.createElement(InstanceLink, { to: "/scripts?dir=".concat(curPosition_1) }, dirName);
            });
        }
    }
    return (React.createElement(ContainerBox, null,
        React.createElement(PageHeader, { title: title, documentTitle: "Scripts", subTitle: subTitles },
            React.createElement(InstanceLink, { to: "/scripts/create?".concat(new URLSearchParams({ rootDir: pageStore.currentDirPath }).toString()), className: "btn btn-primary" },
                React.createElement(Icon, { className: "create-new-btn" }),
                "Create new")),
        body));
}));
